import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { initGA, logEvent, logPageView } from './utils/analytics'
import { BIO, IMAGE, TITLE } from './utils/constants.js'
import { getRandomGradient, getRandomGradientDirection, shuffleLinks } from './utils/helpers.js'

const LinkButton = ({ index, link }) => {
  const IconComponent = link.icon

  const handleClick = () => {
    logEvent(
      'Link',
      'click',
      JSON.stringify({
        title: link.title,
        index: index,
        icon: IconComponent.name,
      })
    )
  }

  return (
    <a
      aria-label={`Go to ${link.title}`}
      className="bg-gradient-to-r hover:from-pink-400 hover:to-orange-400 from-pink-500 to-orange-500 text-white rounded-lg py-4 px-5 flex shadow-lg w-full"
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      <div className="flex items-center w-full">
        <IconComponent stroke={1.5} className="flex-shrink-0" size={30} />
        <span className="text-sm font-medium text-center flex-grow sm:text-base">{link.title}</span>
        <IconComponent className="opacity-0 flex-shrink-0" />
      </div>
    </a>
  )
}

export default function App() {
  const location = useLocation()
  const randomGradient = useMemo(getRandomGradient, [])
  const randomGradientDirection = useMemo(getRandomGradientDirection, [])
  const shuffledLinks = useMemo(shuffleLinks, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      initGA()
    }
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      logPageView()
    }
  }, [location])

  return (
    <div
      className={`flex justify-center items-center min-h-screen ${randomGradientDirection} ${randomGradient} font-inter text-white pb-10`}
    >
      <div className="p-10 rounded-2xl shadow-2xl w-full max-w-prose my-10 bg-gray-800 ">
        <img
          src={IMAGE}
          alt="explodedsoda's beautiful face"
          width={120}
          height={120}
          className="rounded-full mx-auto"
        />
        <h1 className="text-center text-xl my-2">{TITLE}</h1>
        {BIO.map((text, index) => (
          <h2 key={index} className="text-center text-sm">
            {text}
          </h2>
        ))}
        <h2 className="text-center text-md  my-2">📍 Raleigh, NC</h2>
        <div className="mt-4 space-y-4">
          {shuffledLinks.map((link, index) => (
            <LinkButton index={index} key={index} link={link} />
          ))}
        </div>
      </div>
    </div>
  )
}
