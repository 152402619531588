import ReactGA from 'react-ga4'

export const initGA = () => {
  ReactGA.initialize('G-81147WVFX8')
}

export const logPageView = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  })
}

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
    send_to: 'G-81147WVFX8',
  })
}
