import {
  IconBeer,
  IconBeerFilled,
  IconBook2,
  IconBooks,
  IconBrandAmazon,
  IconBrandBluesky,
  IconBrandDiscord,
  IconBrandInstagram,
  IconBrandLastfm,
  IconBrandMastodon,
  IconBrandThreads,
  IconBrandTiktok,
  IconBrandTwitch,
  IconCarambola,
  IconChartBubble,
  IconChartBubbleFilled,
  IconCheese,
  IconEggFried,
  IconPaw,
  IconShoppingCart,
  IconSparkles,
  IconStar,
  IconStars,
  IconTipJar,
} from '@tabler/icons-react'

const randomGeneratorIcons = [
  IconCarambola,
  IconChartBubble,
  IconChartBubbleFilled,
  IconCheese,
  IconEggFried,
  IconSparkles,
  IconStar,
  IconStars,
]
const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

const getTipTitle = () => {
  let title = getRandomNumber(0, 1) ? 'Tip Jar' : `${getRandomNumber(0, 1) ? 'Send' : 'Give'} me money`
  if (getRandomNumber(0, 1) && getRandomNumber(0, 1) && getRandomNumber(0, 1)) {
    title = 'F you, pay me'
  }
  return title
}

const links = [
  {
    title: 'Furry Trash Apparel',
    url: 'https://furrytrashco.com',
    icon: IconShoppingCart,
  },
  {
    title: 'Wishlist (affiliate link)',
    url: 'https://www.amazon.com/hz/wishlist/ls/1FRHHMA8Z654C?reveal=purchased&tag=esx0pd14-20',
    icon: IconBrandAmazon,
  },
  {
    title: getTipTitle(),
    url: `${window.location.origin}/tip`,
    icon: IconTipJar,
  },
  {
    title: 'Username Generator',
    url: 'https://usernames.explodedsoda.me/',
    icon: randomGeneratorIcons[getRandomNumber(0, randomGeneratorIcons.length - 1)],
  },
  {
    title: 'Domain Name Generator',
    url: 'https://domains.explodedsoda.me/',
    icon: randomGeneratorIcons[getRandomNumber(0, randomGeneratorIcons.length - 1)],
  },
  {
    title: 'Instagram',
    url: 'https://instagram.com/explodedsoda',
    icon: IconBrandInstagram,
  },
  {
    title: 'Bluesky',
    url: 'https://bsky.app/profile/explodedsoda.bitch.vision',
    icon: IconBrandBluesky,
  },
  {
    title: 'Threads',
    url: 'https://www.threads.net/@explodedsoda',
    icon: IconBrandThreads,
  },
  {
    title: 'Goodreads',
    url: 'https://www.goodreads.com/user/show/15428452-kyle-anne',
    icon: getRandomNumber(0, 1) ? IconBook2 : IconBooks,
  },
  {
    title: '24/7 Chat Mini Game & Radio Stream',
    url: 'https://www.twitch.tv/comfycozystream/chat',
    icon: IconBrandTwitch,
  },
  {
    title: 'Join our Comfy Cozy Discord Server!',
    url: 'https://discord.gg/XZEWuByRgF',
    icon: IconBrandDiscord,
  },
  {
    title: 'Tiktok',
    url: 'https://www.tiktok.com/@explodedsoda',
    icon: IconBrandTiktok,
  },
  {
    title: 'Untappd',
    url: 'https://untappd.com/user/explodedsoda',
    icon: getRandomNumber(0, 1) ? IconBeer : IconBeerFilled,
  },
  {
    title: 'Last.fm',
    url: 'https://www.last.fm/user/explodedsoda',
    icon: IconBrandLastfm,
  },
  {
    title: 'Mastodon - Toot.cat',
    url: 'https://toot.cat/@explodedsoda',
    icon: IconBrandMastodon,
  },
  {
    title: 'Looking for my fursona, Khora the Caracal?',
    url: 'https://khora.lol',
    icon: IconPaw,
  },
]

export default links
